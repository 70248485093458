{
  "name": "const-credit-fe",
  "version": "0.0.0",
  "scripts": {
    "watch": "ng test",
    "build": "ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.6",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cdk": "^17.0.3",
    "@angular/cli": "~17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@angular/material": "^17.0.3",
    "@sparbanken-syd/const-credit-be": "next",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.2.2",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.2.4",
    "@types/jasmine": "~4.3.0",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@typescript-eslint/eslint-plugin": "6.13.1",
    "@typescript-eslint/parser": "6.13.1",
    "eslint": "^8.54.0",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.31",
    "stylelint": "^15.11.0",
    "stylelint-config-sass-guidelines": "^10.0.0",
    "stylelint-config-standard-scss": "^11.0.0",
    "typescript": "~5.2.2"
  }
}
